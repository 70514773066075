<template>
  <v-banner
    color="black"
    icon="mdi-account"
    lines="three"
  >
    <template v-slot:text v-if="fullname!==null && date_today!==null">
      {{fullname+' - '+date_today}}
    </template>
  </v-banner>

  <v-banner
    color="black"
    icon="mdi-cart"
    lines="three"
  >
    <template v-slot:text v-if="categories!==null">
      {{categories}}
    </template>
  </v-banner>

  <v-banner
    color="black"
    icon="mdi-cart-outline"
    lines="three"
  >
    <template v-slot:text v-if="products!==null">
      {{products}}
    </template>
  </v-banner>

  <v-banner
    color="black"
    icon="mdi-cash-usd"
    lines="three"
  >
    <template v-slot:text>      
      {{orders_delivered}} Pedidos Entregados
    </template>
  </v-banner>

  <v-banner
    color="warning"
    icon="mdi-access-point"
    lines="one"
  >
    <template v-slot:text>      
    </template>

    <template v-slot:actions>
      <v-btn large @click="conectarse()">
        Conectarse
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import Swal from 'sweetalert2';
import API from '../api';
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken, isSupported, onBackgroundMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCVyZWY2af-JJf2V-gvVPFAW7ANDWzJNI0",
  authDomain: "cropchien-b4abf.firebaseapp.com",
  projectId: "cropchien-b4abf",
  storageBucket: "cropchien-b4abf.appspot.com",
  messagingSenderId: "980970251855",
  appId: "1:980970251855:web:df66971ae5ed35025eec3a",
  measurementId: "G-JZQ3Q4D3F3"
};

const app = initializeApp(firebaseConfig);

export default {
  data(){
    return { 
      token_street_shopping: null,
      loading: false,
      img1: require('@/assets/i1.jpg'),
      category: "Frutas Colombianas",
      is_subscription: 0,
      fullname: null,
      date_today: null,
      products: null,
      categories: null,
      orders_delivered: 0
    }
  },
  mounted(){
    this.token_street_shopping = localStorage.getItem('token_street_shopping');
    this.getAccessToPage();
  },
  methods: {
    getAccessToPage() {
      this.loading = true;
      localStorage.removeItem('user_id_street_shopping');
      if (this.token_street_shopping != null) {
        let token_subscription_street_shopping = localStorage.getItem('token_subscription_street_shopping');
        let formdata = new FormData();
        formdata.append('subscription',token_subscription_street_shopping);
        API.post('api.php', formdata, { headers: {
          'Authorization': this.token_street_shopping,
          'Route': 'validate_token'
        }})
        .then(response => {
          if (response.data.transaction_status && response.data.transaction_status.code == 200) {
            this.is_subscription = response.data.Data.is_subscription;
            this.fullname = response.data.Data.fullname;
            this.date_today = response.data.Data.date_today;
            this.products = response.data.Data.products;
            this.categories = response.data.Data.categories;
            this.user_id_vendor = response.data.user_id;
            this.authNotification();
            localStorage.setItem('user_id_street_shopping',response.data.Data.user_id);
            localStorage.setItem('is_connected_street_shopping','false');
            localStorage.setItem('order_accepted_street_shopping',0);
            if (response.data.Data.order_accepted > 0) {
              localStorage.setItem('order_accepted_street_shopping', response.data.Data.order_accepted);
              this.$router.push('/ventas');
            } else if (response.data.Data.connected == 1) {
              localStorage.setItem('is_connected_street_shopping', 'true');
              this.$router.push('/clients_in_the_area');
            }
          } else if (response.data.transaction_status && process.env.VUE_APP_API_INVALID_TOKEN.includes(response.data.transaction_status.code)) {
            localStorage.removeItem('token_street_shopping');
            this.$router.push("/login");
          } else {
            Swal.fire({
              title: 'Error',
              html: 'Fallas en el servidor, intenta nuevamente',
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
          }
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            html: 'Se ha presentado un error, intenta nuevamente',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
          console.log('error front ' + error)
        })
        .finally(() => this.loading = false)
      } else {
        this.$router.push('/login');
      }
    },

    authNotification() {
      if (!("Notification" in window)) {
        Swal.fire({
          title: 'Atención',
          html: 'Este dispositivo no soporta notificaciones',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
        is_subscription = 'true';
      } //if (Notification.permission == 'granted') {
      if (this.is_subscription == 0) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            this.initializeFirebase();
          } else {
            Swal.fire({
              title: 'Atención',
              html: 'No se autorizo el envio de notificaciones en este dispositivo',
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
          }
        })
      }
      this.notificationsForeground();
    },
       
    registerToken(token) {
      this.token_street_shopping = localStorage.getItem('token_street_shopping');
      let formdata = new FormData();
      formdata.append('subscription',token);
      API.post('api.php', formdata, { headers: {
        'Authorization': this.token_street_shopping,
        'Route': 'set_save_subscription'
      }})
      .then(response => {
      if (response.data.transaction_status && response.data.transaction_status.code == 200) {
        localStorage.setItem('token_subscription_street_shopping', token);
        Swal.fire({
          title: 'Atención',
          html: 'Se autorizaron las notificaciones',
          icon: 'info',
          confirmButtonText: 'Aceptar',
        });
      } else if (response.data.transaction_status && process.env.VUE_APP_API_INVALID_TOKEN.includes(response.data.transaction_status.code)) {
        localStorage.removeItem('token_street_shopping');
        this.goView('/login');
      } else {
        Swal.fire({
          title: 'Error interno',
          html: 'Se ha presentado una falla en el servidor',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }})
      .catch(error => {
        Swal.fire({
          title: 'Error en la App',
          html: 'Se ha presentado una falla en la App',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      })
      .finally(() => this.loading = false)
    },
      
    initializeFirebase() {
      if (isSupported()) {
        const messaging = getMessaging(app);
        getToken(messaging)
          .then((token) => {
            console.log(token);
            this.registerToken(token)
          })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      }

    },

    notificationsForeground() {
      const messaging = getMessaging(app);
        
      onMessage(messaging, function (payload) {
        let jpayload = JSON.parse(payload.data.notification);
        
        const notificationTitle = jpayload.title;
        const notificationOptions = {
          body: jpayload.body,
          icon: jpayload.icon,
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: "Nuevo Pedido",
        };
        
        try {
          navigator.serviceWorker.register("/sw.js");
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(notificationTitle, notificationOptions);
          });
        } catch (e) {
          console.log(e instanceof TypeError); // true
          console.log(e.message); // "null has no properties"
          console.log(e.name); // "TypeError"
          console.log(e.stack); // Stack of the error
        }       
        
        let token_street_shopping = localStorage.getItem('token_street_shopping');
        let formdata = new FormData();
        formdata.append('order_id', jpayload.orderid);
        API.post('api.php', formdata, { headers: {
          'Authorization': token_street_shopping,
          'Route': 'get_view_new_order'
        }})
        .then(response => {
          if (response.data.transaction_status && response.data.transaction_status.code == 200) {
            let view_new_order = response.data.Data;
            Swal.fire({
              title: 'NUEVO PEDIDO ?',
              html: view_new_order.first_name + '<br>' +
                view_new_order.customer_address + '<br>' +
                view_new_order.category_name + '<br>' +
                view_new_order.list_products,
              icon: 'success',
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: 'SI, Aceptar',
              cancelButtonText: 'NO, Rechazar',
              customClass: {
                confirmButton: 'my-confirm-button-class',
                cancelButton: 'my-cancel-button-class',
                timerProgressBar: 'my-timer-progress_bar-class'
              },
              timer: 15000,
              timerProgressBar: true,
            }).then(result => {
              if (result.isConfirmed) {
                this.loading = false
                let formdata = new FormData();
                formdata.append('order_id', jpayload.orderid);
                API.post('api.php', formdata, { headers: {
                  'Authorization': token_street_shopping,
                  'Route': 'set_accept_new_order'
                }})
                .then(response => {
                  if (response.data.transaction_status && response.data.transaction_status.code == 200) {
                    Swal.fire({
                      title: 'Pedido Aceptado',
                      showCloseButton: true,
                      html: 'Dirigete a donde: <br>' +
                        view_new_order.first_name + '<br>' +
                        view_new_order.customer_address + '<br>' +
                        view_new_order.category_name + '<br>' +
                        view_new_order.list_products,
                      confirmButtonText: 'Aceptar',
                    });
                  } else if (response.data.transaction_status && process.env.VUE_APP_API_INVALID_TOKEN.includes(response.data.transaction_status.code)) {
                    localStorage.removeItem('token_street_shopping');
                    this.$router.push("/login");
                  } else {
                    Swal.fire({
                      title: 'Error',
                      html: 'Fallas en el servidor, intenta nuevamente',
                      icon: 'error',
                      confirmButtonText: 'Aceptar',
                    });
                  }
                })
                .catch(error => {
                  Swal.fire({
                    title: 'Error',
                    html: 'Se ha presentado un error, intenta nuevamente ',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                  });
                  console.log('error front ' + error);
                })
                .finally(() => this.loading = false)
              }
            })
          } else if (response.data.transaction_status && process.env.VUE_APP_API_INVALID_TOKEN.includes(response.data.transaction_status.code)) {
            console.log('error axios');
          } else {
            console.log('error 2 axios');
          }
        })
        .catch(error => {
          console.log('error 3 axios');
        })

      });
    },
    conectarse() {
      this.loading = true;
      this.token_street_shopping = localStorage.getItem('token_street_shopping');      
      let formdata = new FormData();
      formdata.append('connect', 1);
      API.post('api.php', formdata, { headers: {
        'Authorization': this.token_street_shopping,
        'Route': 'set_connect'
      }})
      .then(response => {
        if (response.data.transaction_status && response.data.transaction_status.code == 200) {
          if (response.data.Data.connected == 1) {
            localStorage.setItem('is_connected_street_shopping', 'true');
            this.$router.push('/clients_in_the_area');
          } else {
            Swal.fire({
              title: 'Atención',
              html: response.data.Data.error,
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
          }
        } else if (response.data.transaction_status && process.env.VUE_APP_API_INVALID_TOKEN.includes(response.data.transaction_status.code)) {
          localStorage.removeItem('token_street_shopping');
          this.goView('/login');
        } else {
          Swal.fire({
            title: 'Error interno',
            html: 'Se ha presentado una falla en el servidor',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      })
      .catch(error => {
          Swal.fire({
            title: 'Error en la App',
            html: 'Se ha presentado una falla en la App',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
      })
      .finally(() => this.loading = false)      
    },
    
  }

}
</script>

<style>
  .my-confirm-button-class {
    background-color: green !important; 
    color: #FFFFFF !important;
  }
  .my-cancel-button-class {
    background-color: red !important; 
    color: #FFFFFF !important;
  }
  .my-timer-progress_bar-class {
    background-color: magenta !important; 
    color:  magenta !important;
    height: 1rem
  }  
</style>